import React, { useContext } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap, {Power3} from 'gsap';

import TransitionContext from '../../context/TransitionContext';

const TransitionComponent = ({ children }) => {
    const location = useLocation();
    const { toggleCompleted } = useContext(TransitionContext);

    return (
        <SwitchTransition>
            <Transition
                key={location.pathname}
                timeout={500}
                onEnter={(node) => {
                    //console.log(node.children)
                    // node.forEach((n) => console.log(n))
                    
                    toggleCompleted(false);
                    node.children.forEach((n, i) => {
                        gsap.set(n, { autoAlpha: 0, scale: 1, y: 100, ease: Power3.InOut, });
                        gsap.timeline({
                                paused: true,
                                onComplete: () => toggleCompleted(true),
                            })
                            .to(n, { autoAlpha: 1, y: 0, duration: .5, ease: Power3.InOut, delay: i/4 })
                            // .to(node, { scale: 1, duration: 1 })
                            .play();
                    })
                    
                }}
                onExit={(node) => {
                    gsap.timeline({ paused: true })
                        // .to(node, { scale: 1, duration: .5 })
                        .to(node, { y: window.innerHeight/4, autoAlpha: 0, duration: .75, ease: Power3.InOut, })
                        .play();
                }}
            >
                {children}
            </Transition>
        </SwitchTransition>
    )
}

export default TransitionComponent;