import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
//import styles from './Home.module.scss'
import HomeContent from './HomeContent'
// const HomeContent = React.lazy(() => import('./HomeContent'));

function Home() {
	const location = useLocation();

  	useEffect(() => {
		window.gtag("event", "page_view", {
			page_path: location.pathname + location.search + location.hash,
			page_search: location.search,
			page_hash: location.hash,
			page_title: "Home"
		});
	}, [location]);

	return (
		<div id='home'>
			{/* <Suspense fallback={<div></div>}> */}
				<HomeContent />
			{/* </Suspense> */}
		</div>
	)
}

export default Home