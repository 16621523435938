import React from 'react'
import styles from './Footer.module.scss'
import twitter from '../../images/twitter.png'

function Footer() {
  return (
    <div className={styles.footerCont}>
        <p>CPC Studio</p>
        <div className={styles.footerLinksCont}>
            <a href='https://twitter.com/studio_cpc' target='_blank' rel='noreferrer'><img src={twitter} id={styles.tw} alt='twitter'/></a>
        </div>

    </div>
  )
}

export default Footer