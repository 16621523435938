import React from 'react'
import styles from './Navbar.module.scss'
import {useNavigate} from 'react-router-dom';
import 'animate.css';

function Navbar() {
  	const navigate = useNavigate();
	
	let content;
	function handleNav(e) {
		let path = window.location.pathname.split('/');
		console.log(path)
		if (e.split("/")[1] !== path[1] ) {
			if (path[1] === '') {
				content = document.getElementById('home')
			} else if (path[1] === 'about') {
				content = document.getElementById('about')
			} else if (path[1] === 'work') {
				content = document.getElementById('work')
			} else if (path[1] === 'contact') {
				content = document.getElementById('contact')
			}
			if (content) {
				content.classList.remove("animate__slow")
				content.classList.add("animate__animated", "animate__fadeOut", "animate__faster")
			} else {
				navigate(e)
			}
			
		}
		navigate(e)
		
	}

	return (
		<div className={styles.navCont}>
			<button onClick={() => handleNav('/')}><h1>CPC STUDIO;</h1></button>
			<div className={styles.navLinksCont}>
				<button onClick={() => handleNav('/about')}>about</button>
				<button onClick={() => handleNav('/work')}>work</button>
				<button id='contactBtn' onClick={() => handleNav('/contact')}>contact</button>
			</div>
		</div>
	)
}

export default Navbar