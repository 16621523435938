import {Routes, Route} from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'animate.css';
import Home from './assets/components/Home/Home'
import Navbar from './assets/components/Navbar/Navbar';
import styles from './App.module.scss';
import Footer from './assets/components/Footer/Footer';
import About from './assets/components/About/About';
import Contact from './assets/components/Contact/Contact';
import { TransitionProvider } from './assets/context/TransitionContext';
import TransitionComponent from './assets/components/Transition/Transition';
import Work from './assets/components/Work/Work';

function App() {
	const [screenLoading, setScreenLoading] = useState(false);

	useEffect(() => {
		setScreenLoading(true);
		setTimeout(() => {
			//document.getElementById("loader").classList.add("animate__fadeOut")
			setScreenLoading(false);
		}, 1000);
	}, []);

	return (
		<>
			{screenLoading ? (
				<div className={styles.App}>
					<div className={styles.AppCont}>
						<Navbar/>
						<div id='loader' className={`${styles.loaderCont} animate__animated animate__fadeIn`}>
							<div className={styles.loader}></div>
						</div>
						
					</div>
				</div>
				
			) : (
				<div className={styles.App}>
					<div className={styles.AppCont}>
						<Navbar/>
						<TransitionProvider>
							<Routes>
								<Route index path='/' element={
									<TransitionComponent>
										<Home/>
									</TransitionComponent>
								}/>
								<Route path='/work' element={
									<TransitionComponent>
										<Work/>
									</TransitionComponent>
								} />
								<Route path='/about' element={
									<TransitionComponent>
										<About/>
									</TransitionComponent>
								} />
								<Route path='/contact' element={
									<TransitionComponent>
										<Contact/>
									</TransitionComponent>
								} />
							</Routes>
						</TransitionProvider>
						<Footer/>
					</div>
				</div>
			)}
		</>
		
	);
}

export default App;
