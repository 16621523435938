import React, {useEffect} from 'react'
import { useLocation } from "react-router-dom";
import styles from './About.module.scss'
import project1 from '../../images/ue.png'
import project2 from '../../images/plumbing.png'
import project3 from '../../images/talrd.png'
import project4 from '../../images/auraVista.png'
import { ReactP5Wrapper } from '@p5-wrapper/react'
import buff from './buffer'
import 'animate.css';
import {useNavigate} from 'react-router-dom';

function About() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
            page_title: "About"
        });
    }, [location]);

    let content;
	function handleNav(e) {
		let path = window.location.pathname;
		if (e !== path) {
			if (path === '/about') {
				content = document.getElementById('about')
                content.classList.remove("animate__slow")
			    content.classList.add("animate__animated", "animate__fadeOut", "animate__faster")
			} 
		}
		//setTimeout(() => {
		navigate(e)
		//}, 500);
	}

    return (
        <div id='about' className={`${styles.aboutCont} animate__animated animate__fadeIn animate__slow`}>
            <div className={styles.aboutHead}>
                <h1>Creativity. Production. Community.</h1>
                <h2>Let's build your dream.</h2>
            </div>
            <section className={styles.aboutSect}>
                <div className={styles.aboutSectText}>
                    <h2>Who We Are</h2>
                    <p>We're CPC Studio, and we're all about creating memorable 
                        experiences! As designers, branding experts, and startup 
                        founders, we craft resonant products from concept to launch. 
                        Join us in shaping the future of design, one innovative 
                        idea at a time.
                    </p>
                </div>
            </section>

            <div className={styles.aboutBuffer} id='bufferDiv'>
                <ReactP5Wrapper sketch={buff} />
            </div>

            <section className={styles.aboutSect}>
                <div className={styles.aboutSectText}>
                    <h2>What We Value</h2>
                    <p>Innovation, Collaboration, Authenticity. At CPC Studio, we're 
                        dedicated to crafting memorable experiences through design 
                        and branding. We innovate, collaborate, and stay authentic 
                        as we shape the future of design, one idea at a time.
                    </p>
                </div>
            </section>

            <div className={styles.aboutBuffer}>
                <ReactP5Wrapper sketch={buff} />
            </div>

            <section className={styles.aboutSect}>
                <div className={styles.aboutProjects}>
                    <div className={styles.aboutProjectsText}>
                        <h2>What We've Done</h2>
                        <button className={styles.worksLink} onClick={() => handleNav('/work')}>See Our Work ={'>'}</button>
                    </div>

                    <div className={styles.aboutClientsCont}>
                        <img src={project4} alt='' className={styles.aboutClientImg} />
                        <img src={project2} alt='' className={styles.aboutClientImg} />
                        <img src={project3} alt='' className={styles.aboutClientImg} />
                        <img src={project1} alt='' className={styles.ueImg} />
                    </div>
                </div>
            </section>
        </div>  
    )
}

export default About