import React from 'react'
import styles from './Home.module.scss'
import { ReactP5Wrapper } from '@p5-wrapper/react'
import 'animate.css';
import sphere from './sphere'
import project1 from '../../images/ue.png'
import project2 from '../../images/plumbing.png'
import project3 from '../../images/talrd.png'
import project4 from '../../images/auraVista.png'
import cube from '../../images/cube.png'
import cube2 from '../../images/cube2.png'
import cube3 from '../../images/cube3.png'
import cube4 from '../../images/cube4.png'
import mail from '../../images/mail.png'
import Project from './Projects/Project';
import {useNavigate} from 'react-router-dom';

function HomeContent() {
    const navigate = useNavigate();
    const observer = new IntersectionObserver(entries => {
        console.log('observing')
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate__animated', 'animate__fadeIn');
                entry.target.classList.remove('hidden');
            }
        })
    })
    const projects = document.querySelectorAll('.projectCont');
    projects.forEach((e) => observer.observe(e))



    return (
        <div className={`${styles.homeCont} animate__animated animate__fadeIn animate__slower`}>
            <div className={styles.hero}>
                <div className={styles.homeHeroCont}>
                    <h1 className='animate__animated animate__fadeInUp animate__slow'>
                        Identity Matters<br/> We'll Help Build Yours
                    </h1>
                    <p className='animate__animated animate__fadeInUp animate__delay-1s animate__slow'>
                        Standing out can be hard, but with good people and a good product, recognition follows. We're here to help you get there.
                    </p>
                </div>
                <div className='animate__animated animate__zoomIn animate__slower'>
                    <ReactP5Wrapper sketch={sphere} />
                </div>
                
            </div>
            
            <div className={`${styles.homeHeroCont} ${styles.buffer}`}>
                <h3>At CPC Studio we're all about turning your idea into an experience.<br/> Design, development, branding, or marketing, we'll get you where you need to go.</h3>
            </div>
            
            <section>
                <div className={styles.homeHeroContTwo}>
                    <h2>Our Process</h2>
                    <div className={styles.processes}>
                        <div className={styles.processCont}>
                            <div className={styles.processStep}>
                                <h3>1. Discovery</h3>
                                <p>We thoroughly discuss your business goals, target audience, and branding guidelines. 
                                    Through market research and competitor analysis, we identify industry trends and project 
                                    scope, establishing a clear timeline and milestones.</p>
                                <img src={cube} alt=''/>
                            </div>
                            <div className={styles.processStep}>
                                <h3>2. Design</h3>
                                <p>Our designers create wireframes and mockups based on our discussions, refining them 
                                    iteratively until alignment is achieved. High-fidelity prototypes are then developed 
                                    to showcase website functionality and aesthetics.</p>
                                <img src={cube2} alt=''/>
                            </div>
                        </div>
                        <div className={styles.processCont}>
                            <div className={styles.processStep}>
                                <h3>3. Development</h3>
                                <p>Our team transforms approved designs into a functional product, prioritizing responsiveness 
                                    and performance. Continuous communication with you ensures prompt feedback and adjustments 
                                    throughout the development process.</p>
                                <img src={cube3} alt=''/>
                            </div>
                            <div className={styles.processStep}>
                                <h3>4. Deployment</h3>
                                <p>We conduct rigorous testing to identify and resolve bugs and ensure cross-browser 
                                    compatibility. Upon your approval, we manage the smooth deployment of the website to the 
                                    live environment.</p>
                                <img src={cube4} alt=''/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section>
                <div className={styles.homeHeroContThree}>
                    <h1>Our Work</h1>
                    <div className={styles.listsCont}>
                        <ul className={styles.projectList}>
                            <li className={styles.tempDiv}></li>
                            <Project proj={{ image: project3, name: "TLRD", work: ["Design", " Development"]}}/>
                            <Project proj={{ image: project1, name: "Urban Echo", work: ["Design", " Marketing"]}}/>
                            
                        </ul>
                        <ul className={styles.projectList}>
                            <Project proj={{ image: project2, name: "Boss Plumbing", work: ["Design", " Development"]}}/>
                            <Project proj={{ image: project4, name: "Aura Vista", work: ["Design", " Development"]}}/>
                            
                        </ul>
                    </div>
                </div>
            </section>
            <section>
                <div className={styles.homeHeroContTwo}>
                    <h2>Whats New?</h2>
                    <div className={styles.blogFeed}>
                        <a href='https://abduzeedo.com/building-memorable-e-commerce-brand-canary-identity' className={styles.blogPost} target='_blank' rel="noreferrer">
                            <span>3/4/2024</span>
                            <h3>Building a Memorable Brand</h3>
                            <p>{'>'} Read More</p>
                        </a>
                        <a href='https://www.awwwards.com/case-study-crafting-a-motion-experience-on-webflow-platform.html' target='_blank' rel="noreferrer" className={styles.blogPost}>
                            <span>2/28/2024</span>
                            <h3>Motion in the UX</h3>
                            <p>{'>'} Read More</p>
                        </a>
                        <a href='https://www.chameleon.io/blog/spooky-inapp-ux' target='_blank' rel="noreferrer" className={styles.blogPost}>
                            <span>2/20/2024</span>
                            <h3>Removing "Spooky" UX</h3>
                            <p>{'>'} Read More</p>
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className={styles.contactCont}>
                    <h2>Contact</h2>
                    <h3>Have a project, an idea, or a question? Send us an email and we'll get back to you soon.</h3>
                    <button className={styles.contactEmail}  onClick={()=> navigate('./contact')}>
                        <img src={mail} alt=''/>
                        <p>EMAIL</p>
                        <p>{'>'}</p>
                    </button>
                </div>
            </section>

        </div>
    )
}

export default HomeContent