import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
// import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById('root'));
//ReactGA.initialize("G-HJQHBT5125");
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// const SendAnalytics = ()=> {
// 	ReactGA.send({
// 	  hitType: "pageview",
// 	  page: window.location.pathname,
// 	});
// }
// reportWebVitals(SendAnalytics);