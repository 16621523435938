import React from 'react'
import styles from './Project.module.scss'
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 100 }
};

function Project({proj}) {
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
          control.start("visible");
        } 
    }, [control, inView]);

    return (
        <motion.div 
            className={styles.projectCont}
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
        >
            <img src={proj.image} alt='' className={styles.tempImg}></img>
            <h3>{proj.name}</h3>
            <p>{proj.work.toString()}</p>
        </motion.div>
    )
}

export default Project