import React, {useEffect} from 'react'
import { useLocation } from "react-router-dom";
import styles from './Contact.module.scss';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import buffer from './outerBuffer';

function Contact() {
    const location = useLocation();

    useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
            page_title: "Contact"
        });
    }, [location]);

    return (
        <div id='contact' className={`${styles.contactCont} animate__animated animate__fadeIn animate__slow`}>
            <div className={styles.contactOuter}>
                <ReactP5Wrapper sketch={buffer} />
                <div className={styles.contactInner} id='contactInner'>
                    <div className={styles.contactHead}>
                        <h2>What's on your mind?</h2>
                        <p>Fill out the form below and we'll get back to you soon.</p>
                    </div>
                    <div>
                        <form
                            className={styles.contactForm}
                            id="contactForm"
                            action="https://formspree.io/f/mzbnvyjo"
                            method="POST"
                        >
                            <input
                                className={styles.contactInput}
                                type="text"
                                name="name"
                                placeholder="NAME*"
                                required
                            />
                            <input
                                className={styles.contactInput}
                                type="email"
                                name="_replyto"
                                placeholder="EMAIL*"
                                required
                            />
                            <input className={styles.contactInput} type="text" name="subject" placeholder="SUBJECT" />

                            <textarea className={styles.contactInput} name="message" placeholder="MESSAGE"></textarea>

                            <input className={styles.contactSubmit} type="submit" value="SEND" />
                                    
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Contact