import React, {useEffect} from 'react'
import styles from './Work.module.scss'
import WorkProject from './WorkProject/WorkProject'
import talrd from '../../images/talrd.png'
import aura from '../../images/auraVistaFinal.png'
import plumb from '../../images/plumbing.png'
import ue from '../../images/ue.png'
import cube from './cube'
import { ReactP5Wrapper } from '@p5-wrapper/react'

function Work() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id='work' className={`${styles.worksCont} animate__animated animate__fadeIn animate__slow`}>
            <div className={styles.worksHero}>
                <div>
                    <h1>Explore Our Creations<br/>Transofrming Ideas into Reality</h1>
                    <p>We're more than a team – we're your partners in turning dreams into reality. With a 
                        passion for design and development, we seamlessly blend creativity and technical 
                        expertise to bring your vision alive.
                    </p>
                </div>
                <ReactP5Wrapper sketch={cube} />
            </div>
            <div className={styles.workProjectCont}>
                <div className={styles.workProject}>
                    <WorkProject proj={{
                        name:"TALRD", 
                        tagline:"Combining an AI vision model with e-commerce.", 
                        link:"https://talrdsuits.com/",
                        desc:"Engineering our latest project involved architecting an e-commerce platform integrating advanced AI computer vision and precision tailoring algorithms. Extensive research, UI/UX design, and rigorous testing were conducted to ensure seamless functionality. Witness the convergence of technical prowess and innovation, redefining the bespoke fashion experience.", 
                        image:talrd}} 
                    />
                </div>
                <div className={styles.workProject} id='av'>
                    <WorkProject proj={{
                        name:"Boss Plumbing", 
                        tagline:"Rebranding a small business, desiging a new online identity.", 
                        link: "https://www.bossplumbingcorp.net/",
                        desc:"Collaborating with Boss Plumbing, a longstanding local plumbing business, we created a comprehensive rebranding and website development project. Through multiple interviews and market analysis, we crafted a modern brand identity, including a new logo and visual elements. Simultaneously, our team built a user-friendly website showcasing their services and testimonials. The launch strategy and ongoing support post-launch resulted in an elevated online presence for Boss Plumbing Services, driving increased website traffic, customer inquiries, and business opportunities.", 
                        image:plumb}} 
                    />
                </div>
                <div className={styles.workProject}>
                    <WorkProject proj={{
                        name:"Aura Vista", 
                        tagline:"Rebranding a Travel Agency, utilizing AI and geolocation techonlogy.", 
                        link: "https://www.auradelvista.com/",
                        desc:"Overhauling our recent project involved integrating AI and geolocation technology to rebrand a travel agency. Our team focused on implementing advanced algorithms and precise geolocation data. Through extensive research, UI/UX design, and thorough testing, we ensured seamless functionality. Experience the result of technical precision and innovation, reshaping the travel experience.", 
                        image:aura}} 
                    />
                </div>
                <div className={styles.workProject} id='av'>
                    <WorkProject proj={{
                        name:"Urban Echo", 
                        tagline:"Designing a cohesive branding image and identity for a clothing company.", 
                        desc:"Assigned to redefine the brand identity for a streetwear clothing company, our project entailed market research, asset design, and the integration of consistent visual elements. Experience the intangible outcome of our work in crafting a distinct identity.", 
                        image:ue}} 
                    />
                </div>
            </div>
            
        </div>
    )
}

export default Work