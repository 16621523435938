import React from 'react'
import styles from './WorkProject.module.scss'
// import {useNavigate} from 'react-router-dom';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 100 }
};

function WorkProject( {proj}) {
	const control = useAnimation()
    const [ref, inView] = useInView()
    //const navigate = useNavigate();

    useEffect(() => {
        if (inView) {
          control.start("visible");
        } 
    }, [control, inView]);

    // function handleNav(e) {
    //     navigate(e)
    // }

    return (
        <motion.div 
            className={styles.workProject}
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
        >
            <div className={styles.workProjectHead}>
				<div>
					<h2>{proj.name}</h2>
					<p>{proj.tagline}</p>
				</div>
				<p>{proj.desc}</p>
                {proj.link && (
                    <a href={proj.link} target='_blank' rel='noreferrer'>Visit ={'>'}</a>
                )}
                
			</div>
			<div className={styles.workImgCont}>
				<img className={styles.workImg} src={proj.image} alt='' />
			</div>
        </motion.div>
    )
}

export default WorkProject