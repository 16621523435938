import * as p5 from "p5";
import { Delaunay } from "d3";

function buffer(p) {
    const NUM_POINTS = 250;
    const VEL = 1;
    
    let points = [];
    let parent;
    let w,h;
    p.setup = () => {
        if (window.location.pathname === '/contact')  {
            parent = document.getElementById("contactInner");
            w = parent.offsetWidth;
            h = parent.offsetHeight;
        } else {
            w = 0
            h = 0
        }

        p.createCanvas(w +20, h +20);
        for (let i = 0; i < NUM_POINTS; ++i) {
            points.push(new Point(p.random(p.width), p.random(p.height)));
        }
        
        p.background(0);
        p.noStroke();
    };
    
    p.draw = () => {
        p.background(0);
        
        while (points.length < NUM_POINTS) points.push(new Point(p.random(p.width), p.random(p.height)));
        
        points = points.map((p) => p.move());
        
        voronoi(points);
    }

    p.windowResized = () => {
        if (window.location.pathname === '/contact')  {
            parent = document.getElementById("contactInner");
            w = parent.offsetWidth;
            h = parent.offsetHeight;
        } else {
            w = 0
            h = 0
        }
        p.resizeCanvas(w +20, h +20)
    }
    
    function voronoi(pts) {
        const d = Delaunay.from(pts.map(pt => [pt.x, pt.y]));
        const voronoi = d.voronoi([0, 0, p.width, p.height]);
        
        for (let i=0; i<pts.length; ++i) try {
            const polygon = voronoi.cellPolygon(i);
            if (!polygon) continue;
            p.fill(...pts[i].col);
            p.beginShape();
            for (const pt of polygon) {
                p.vertex(...pt);
            }
            p.endShape(p.CLOSE);
        } catch(ex) {
            console.log(ex);
        }  
    }
    
    class Point {
        constructor(x, y, vx, vy, col) {
            this.x = x;
            this.y = y;
            if (typeof vx !== "undefined" && typeof vy !== "undefined") {
                this.vx = vx;
                this.vy = vy;
            } else {
                const r = p5.Vector.random2D().mult(VEL);
                this.vx = r.x;
                this.vy = r.y;
            }
            this.col = col || randomRainbow();
        }
      
        move() {
            let nx = this.x + this.vx;
            let ny = this.y + this.vy;
            let nvx = this.vx;
            let nvy = this.vy;
            if (nx < 0 || nx >= p.width) {
                nvx *= -1;
                nx = this.x + this.vx;
            }
            if (ny < 0 || ny >= p.height) {
                nvy *= -1;
                ny = this.y + this.vy;
            }
            return new Point(nx, ny, nvx, nvy, this.col);
        }
    }
    
    function randomRainbow() {
        switch (p.floor(p.random(6))) {
            case 0:
                return [255, 237, 222, p.floor(p.random(255))];
            case 1:
                return [255, 237, 222, p.floor(p.random(255))];
            case 2:
                return [255, 237, 222, p.floor(p.random(255))];
            case 3:
                return [255, 237, 222, p.floor(p.random(255))];
            case 4:
                return [255, 237, 222, p.floor(p.random(255))];
            case 5:
                return [255, 237, 222, p.floor(p.random(255))];
            default:
                console.log("hi");
                return [10, 10, 10];
        }
    }
}

export default buffer;


